import React from "react";
import "./MiddleCard.css";
import jupiter from "../../assest/images/jupiter.png";

const MiddleCard = (props) => {
  return (
    <div className="middlecard mt-5">
      <div className="card mx-auto pb-4">
        <div className="cardhead">
          <h4>{props.title}</h4>
        </div>
        <div className="cardmiddle mb-2 mt-2 text-center text-white">
          {props.dec}
        </div>
        <ul className="mb-5">
          <li className="plan me-5 p-1">
            <a href="https://raydium.io/swap/?inputMint=sol&outputMint=H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6" className="text-center w-full">
              BUY SOLE
            </a>
          </li>
        </ul>
		        <div className="px-4 mb-2 text-center">
          <img src={jupiter} alt="Jupiter" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default MiddleCard;
