import React from "react";
import "./Bullish.css";

//import images
import car from "../../assest/images/car.png";

function Bullish() {
  return (
    <div className="car_section">
      <div
        className="gaming_title"
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <h1 className="mb-32">
          <span>SOLE</span> FULLY DECENTRALIZED <span>MEME</span> AND{" "}
          <span>DeFi</span> PLATFORM!
        </h1>
      </div>
      <div className="container-fluid Bullish_section">
        <div className="row">
          <div
            className="col-md-6 flex justify-center"
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src={car} alt="" className="h-96" />
          </div>
          <div
            className="col-md-6"
            data-aos="zoom-in-up"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className="invest-title">
              <h3>Lets Build it Together</h3>
            </div>
            <h1 className="bushi">$SOLE to the Moon!!!</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bullish;
