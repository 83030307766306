import React from "react";
import "./Tokenomic.css";
import Ninja from "../assest/images/ninja.png";
import Diamond from "../assest/images/diamondIcon.png";
import Coin from "../assest/images/iCoin.png";
import CatIcon from "../assest/images/icon.png";
import Envelop from "../assest/images/envelop.png";
import SquareDiamond from "../assest/images/solana.png";

function Tokenomic() {
  return (
    <div id="tokenomic" className="scroll-mt-64">
      <div
        className="container"
        data-aos="zoom-out"
        data-aos-easing="ease-in-sine"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="tokenomic-heading ">
              <h2>
                IF YOU BELIEVE IN <span>DeFi &amp; MEME</span> THEN YOU
                BELIEVE IN <span>SOLERIUM!</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="pb-24 mt-5 row">
          <div className="flex items-center justify-center col-xl-6 col-lg-12 col-md-12">
            <div
              className="cat-laptop"
              data-aos="fade-right"
              ease-in-sine="ease-in"
            >
              <img src={CatIcon} className="Ninja img-fluid max-h-96" alt="" />
            </div>
          </div>

          <div className="col-xl-6 col-lg-12 col-md-12">
            <div
              className="tokenomic-list"
              data-aos="fade-left"
              ease-in-sine="ease-in"
            >
              <div className="tokenBox-head">
                <h3 className="">TOKENOMICS</h3>
              </div>
              <div className="tokenomic-box">
                <div className="tokenomic-textBox">
                  <img src={CatIcon} alt="" />
                  <p>
                    Total Supply: <span>1.000.000</span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={CatIcon} alt="" />
                  <p>
                    Liquidity: <span>200.000 <em>burned</em></span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={CatIcon} alt="" />
                  <p>
                    LP Staking: <span>700.000 <a href="https://www.pinksale.finance/solana/pinklock/record/ktGP3918wnTSK1ueJoLWvKzXwerjAGqWeKcPNXN2Sie" target="_blamk"><em>locked</em></a></span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={CatIcon} alt="" />
                  <p>
                    Airdrop: <span>100.000</span>
                  </p>
</div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={SquareDiamond} alt="" />
                  <p className="truncate">
                    Contract ID:{" "}
                    <span>
                      <a href="https://solscan.io/token/H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6">
                        H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6
                      </a>
                    </span>
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tokenomic;
