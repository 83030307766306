import React from "react";
import "./Navbar.css";
// import Game from '../Game/Game';

import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import TopSection from "./TopSection";
import Logo from "../assest/images/logo.png";
import { useState } from "react";
import MusicNoteIcon from '@material-ui/icons/MusicNote';

// import discort from "./discort.png"

function Navbar() {
  const [show, setShow] = useState(false);
  return (
    <div className="head">
      <nav className="navbar navbar-expand-xl navbar-dark lg:p-4">
        <div
          className="container-fluid"
          data-aos="zoom-out-down"
          ease-in-sine="ease-in"
        >
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="Solerium" title="Solerium" className="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
            onClick={() => setShow(!show)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${show ? "show" : ""}`}>
            <ul className="navbar-nav ms-auto me-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#tokenomic">
                  Tokennomics
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#roadmap">
                  Roadmap
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://solerium-1.gitbook.io/solerium" target="_blank">
                  Whitepaper
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://ai.solerium.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  SOLEAI
                </a>
              </li>		
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://play.solerium.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  SOLEPLAY
                </a>
              </li>			  
            </ul>
            <form className=" d-flex">
              <div className="navbar-icon lg:ml-8">
                <a
                  href="https://twitter.com/Solerium_io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://t.me/solerium_io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TelegramIcon />
                </a>
                <a
                  href="https://www.tiktok.com/@solerium_io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <MusicNoteIcon />
                </a>				
              </div>
              <a
                href="https://jup.ag/swap/SOL-H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6"
                className="lg:ml-8 button BuyBTN"
              >
                <span className="slrm">BUY SOLE NOW</span>
              </a>
            </form>
          </div>
        </div>
      </nav>
      <div>
        <TopSection />
      </div>
    </div>
  );
}

export default Navbar;
