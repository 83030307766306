import React from "react";
import "./middleSection.css";
import MiddleCard from "../compoents/MiddleSectionComonents/MiddleCard";
import MiddleCardOne from "../compoents/MiddleSectionComonents/MiddleCardone";
// import middle from "../assest/images/original_size.jpg";
import SliderTwo from "../compoents/SliderTwo/SliderTwo";
import Bullish from "../compoents/Cat/Bullish";
// import Card from '../compoents/Card/Card'
import Stats from "../compoents/stats/Stats";
import Tokenomic from "../containers/Tokenomic";
const MiddleSection = () => {
  return (
    <>
      <div className="relative overflow-hidden MainSection">
        <div className="img2"></div>
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div
              className="col-lg-5 col-md-6 col-sm-12 col-xs-2 MiddleCard "
              data-aos="fade-right"
              ease-in-sine="ease-in"
              data-aos-delay="100"
              data-aos-duration="600"
            >
              <MiddleCard
                title="TRADE SOLE"
                dec="You can now buy $SOLE Tokens with SOL"
              />
            </div>
            <div
              className="col-lg-5 col-md-6 col-sm-12 col-xs-2 MiddleCard"
              data-aos="fade-left"
              ease-in-sine="ease-in"
              data-aos-delay="100"
              data-aos-duration="600"
            >
              <MiddleCardOne
                title="STAKE LP"
                dec="Add Liquidity and Stake LP, Earn SOLE"
              />
            </div>
          </div>
        </div>

        <div></div>
      </div>
      <div className="container Mmm">
        <div className="row d-flex justify-content-center ">
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 MiddleCard ">
            <MiddleCard
              title="BUY WITH CRYPTOCURRENCY"
              dec="* Decentralized exchanges below. SOL GAS fees are to be expected"
            />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 MiddleCard">
            <MiddleCardOne
              title="Buy with fiat / credit card"
              dec="* Transaction fees will apply. KYC verification required. Not validated in all countries"
            />
          </div>
        </div>
      </div>
      {/* <div className="MainSection1 ">
        <img src={middle} alt="" className="img-fluid" />
      </div> */}
      <div>
        <Stats />
      </div>

      <Bullish />
      <SliderTwo />
      <div>
        <Tokenomic />
      </div>
    </>
  );
};

export default MiddleSection;
