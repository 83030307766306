import React from "react";
import "./Roadmap.css";

import Zoom from "react-reveal/Zoom";
import Rocket from "../assest/images/roket.png";
import roadmap from "../assest/images/roadmap.png";

import Astronaut from "../assest/images/astronaut.png";
import Robot from "../assest/images/BigRobot.png";
import Alien from "../assest/images/BigAlien.png";

import CheckIcon from "@material-ui/icons/Check";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Montain from "../assest/images/bg.png";

function Roadmap() {
  return (
    <div id="roadmap">
      <div className="pb-5 Roadmap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="py-5 Rocket-img ">
                <div className="w-full row">
                  <div className="col-lg-4 animate-[float-up-down_1.5s_alternate_infinite_ease-in-out]">
                    <img
                      src={Alien}
                      alt="Alien"
                      data-aos="zoom-out-right"
                      className="hidden mx-auto xl:block rocketimg"
                      ease-in-sine="ease-in"
                    />
                  </div>
                  <div className="col-lg-4 animate-[float-up-down_3s_alternate_infinite_ease-in-out]">
                    <img
                      src={Astronaut}
                      alt="Astronaut"
                      data-aos="zoom-out-top"
                      className="block mx-auto rocketimg"
                      ease-in-sine="ease-in"
                    />
                  </div>
                  <div className="col-lg-4 animate-[float-up-down_2s_alternate_infinite_ease-in-out]">
                    <img
                      src={Robot}
                      alt="Robot"
                      data-aos="zoom-out-left"
                      className="hidden mx-auto xl:block rocketimg"
                      ease-in-sine="ease-in"
                    />
                  </div>
                </div>

                <h2 className="mt-5">Roadmap</h2>
              </div>

              <div className="roadmap-heading-container">
                <div className="Roadmap-headings">
                  <h2>
                    Phase&nbsp;1&nbsp;-&nbsp;Launch
                    {/* <CheckIcon /> */}
                  </h2>
                  <h2>Phase&nbsp;2&nbsp;-&nbsp;Growth</h2>
                  <h2>Phase&nbsp;3&nbsp;-&nbsp;Expansion</h2>
                  <h2>Phase&nbsp;4&nbsp;-&nbsp;Utility</h2>
                </div>
                <div className="roadmap-img ps-5">
                  <img
                    src={roadmap}
                    className="img-fluid w-100"
                    alt="roadmap"
                  />
                </div>
              </div>
            </div>
            <div className="radmap-lists">
              <div className="launch border-left posittion">
                <div className="roadmap-lists-heading">
                  <div className="circle-outline">
                    <div className="circle"></div>
                  </div>
                  <h2 className="my-2">Phase&nbsp;1&nbsp;-&nbsp;Launch</h2>
                </div>
                <ul>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <CheckIcon />
                    </span>
                    <span>DexTools, DexScreener Listing</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <CheckIcon />
                    </span>
                    <span>500 Holders</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <CheckIcon />
                    </span>
                    <span>1,000 Telegram members</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <CheckIcon />
                    </span>
                    <span>Website Release</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <CheckIcon />
                    </span>
                    <span>Stake SOLE LP</span>
                  </li>				  
                </ul>
              </div>
              <div className="growth border-left">
                <div className="roadmap-lists-heading">
                  <div className="circle-outline"></div>
                  <h2 className="my-2">Phase&nbsp;1&nbsp;-&nbsp;growth</h2>
                </div>
                <ul>
				                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span> CoinGecko, CoinMarketCap Listings</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <CheckIcon />
                    </span>
                    <span>Community Contests</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <CheckIcon />
                    </span>
                    <span>SOLE AI Dapp</span>
                  </li>				  
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <CheckIcon />
                    </span>
                    <span>2,000 Telegram members</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>2,000 Holders</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Website Improvements</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Marketing through Social Influencers</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Paid Press Releases</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Regular AMA/Telegram Discussions</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Sign more Partnerships</span>
                  </li>
                </ul>
              </div>
              <div className="expansion border-left">
                <div className="roadmap-lists-heading">
                  <div className="circle-outline"></div>
                  <h2 className="my-2">Phase&nbsp;1&nbsp;-&nbsp;expansion</h2>
                </div>
                <ul>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Jupiter, Raydium Listing</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>10,000 Holders</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <CheckIcon />
                    </span>
                    <span>10,000 Telegram Members</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>More Development</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Influencer Marketing Partnerships</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Full-time Social Media Marketing Team</span>
                  </li>
                </ul>
              </div>
              <div className="Utility">
                <div className="roadmap-lists-heading">
                  <div className="circle-outline left-1"></div>
                  <h2 className="my-2">
                    Phase&nbsp;1&nbsp;-&nbsp;Utility <CheckIcon />
                  </h2>
                </div>
                <ul>
                  <li className="d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>20,000 Holders</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>20,000 Telegram Members</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Additional Marketing Partnerships</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Social Media Campaigns</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>Staking Development</span>
                  </li>
                  <li className="my-1 d-flex">
                    <span className="me-2">
                      <FiberManualRecordIcon className="dot" />
                    </span>
                    <span>CEX Listing</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
