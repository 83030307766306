import React from "react";
import "./SliderTwo.css";
import Carousel from "better-react-carousel";

//import images
import BgImage from "../../assest/images/bg.png";

import memecoin from "../../assest/images/memecoin.png";
import CmC from "../../assest/images/cmc.webp";
import ReactImg from "../../assest/images/react.webp";
import jupiter from "../../assest/images/jupiter.png";
import raydium from "../../assest/images/raydium.png";
import dextools from "../../assest/images/dextools.png";
import cyberscope from "../../assest/images/cyberscope.png";
import difi from "../../assest/images/DIFI_Market_gold.png";

function SliderTwo() {
  return (
    <>
      <div className="slider_two">
        <div className="slider-content">
          <h1 className="pb-4">SOLE Partners</h1>
          <Carousel
            cols={4}
            rows={1}
            gap={0}
            loop
            autoplay={2000}
            hideArrow={true}
          >
		              <Carousel.Item>
              <a href="https://www.dextools.io/app/en/solana/pair-explorer/84FV6dbSWAxWriRC631ccu9FVPCNf8UpKHEF614Sxqgw" target="_blank">
              <img width="70%" src={dextools} alt="" />
              </a>
            </Carousel.Item>
			            <Carousel.Item>
              <a href="https://difi.market" target="_blank">
              <img width="70%" src={difi} alt="" />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a href="https://jup.ag/swap/SOL-H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6" target="_blank">
              <img width="70%" src={jupiter} alt="" />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <a href="https://raydium.io/swap/?inputMint=sol&outputMint=H2fjXJsDJq2ghbXzcYJzQ73sWj6A26qZouLtx6wmrd6" target="_blank">
                <img width="70%" src={raydium} alt="" />
              </a>
            </Carousel.Item>
            <Carousel.Item>
            <a href="https://www.cyberscope.io/audits/sole" target="_blank">
              <img width="80%" src={cyberscope} alt="" className="" />
              </a>
            </Carousel.Item>			
		              <Carousel.Item>
            <a href="https://memecoinseason.io/p/solerium" target="_blank">
              <img width="80%" src={memecoin} alt="" className="" />
              </a>
            </Carousel.Item>			
          </Carousel>	
        </div>
      </div>
      <div className="slider_pic">
        <img src={BgImage} alt="" />
      </div>
    </>
  );
}

export default SliderTwo;
